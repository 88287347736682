export default {
  setClientInfo(state, data) {
    state.clientInfo = data
  },
  SET_LOGIN_TIME(state, data) {
    state.loginTime = data
  },
  SET_IS_ADD_MODAL(state, data) {
    state.isAddAccountModalShow = data
  },
  SET_IS_ADD_CARD(state, data) {
    state.isAddCardModalShow = data
  },
  SET_IS_EDIT_CARD(state, data) {
    state.isEditCardModalShow = data
  },
  SET_IS_DELETE_CARD(state, data) {
    state.isDeleteCardModalShow = data
  },
}
