import axios from '@/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getOptions() {
      return new Promise((resolve, reject) => {
        axios.get('/predefines/options').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getAllPredefine() {
      return new Promise((resolve, reject) => {
        axios.get('/predefines').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSinglePredefine(store, data) {
      return new Promise((resolve, reject) => {
        // axios.get(`/predefines/${data}`).then(response => {
        axios.get('/predefines/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addPredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/predefines', { name: data.name, predefine_type: data.predefine_type, options: data.options }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/predefines/${data.id}`, { name: data.name, predefine_type: data.predefine_type, options: data.options }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deletePredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/predefines/${data}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
