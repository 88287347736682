import Vue from 'vue'
// import VueAxios from 'vue-axios'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
// import api from '@axios'
// import VueAxios from 'vue-axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueAxios from 'vue-axios'
import IdleVue from 'idle-vue'
// import VueResource from 'vue-resource'
import VuePaycard from 'vue-paycard'

import AddAccountModal from '@/views/Account/Create.vue'

import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

/* <script src="https://unpkg.com/vue-lazyload/vue-lazyload.js"></script> */

require('@core/assets/fonts/feather/iconfont.css')

Vue.component('AddAccountModal', AddAccountModal)

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 60000 * 60 * 2,
})

// Vue.use(VueLazyload)

// Card
Vue.use(VuePaycard)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
// Vue.use(vueAxios, axios)
// Vue.use(axios)
Vue.use(VueAxios, axios)

// Vue.use(VueResource)
// Vue.use(api)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyCWR4LFPZQ_aDWZIQXn2EwL7L2PLw4CkaM',
//     libraries: 'drawing, visualization', // This is required if you use the Autocomplete plugin
//     // v: '3.26',
//   },
//   drawingControl: true,
//   drawingControlOptions: {
//     position: 'TOP_CENTER',
//     drawingModes: ['MARKER', 'POLYGON'],
//   },
//   // OR: libraries: 'places,drawing'
//   // OR: libraries: 'places,drawing,visualization'
//   // (as you require)
// })
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCWR4LFPZQ_aDWZIQXn2EwL7L2PLw4CkaM',
    libraries: 'places,drawing', // necessary for places input
  },
})

Vue.component('google-map', VueGoogleMaps.Map)
// Vue.component("google-marker", VueGoogleMaps.Marker);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  onIdle() {
    if (Object.keys(store.state.clientInfo).length) {
      store.dispatch('users/logout').then(() => {
        localStorage.removeItem('token')
        store.commit('SET_LOGIN_TIME', '')
        store.commit('setClientInfo', {})
        // eslint-disable-next-line no-restricted-globals
        router.push({ name: 'login' })
      }).catch(err => {
        console.error(err)
      })
    }
  },
  render: h => h(App),
}).$mount('#app')
