import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getBilling() {
      return new Promise((resolve, reject) => {
        axios.get('/billing').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSingleBilling(store, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/billing/invoices/${data}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSuitablePlan() {
      return new Promise((resolve, reject) => {
        axios.get('/billing/subscription/plans').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    startSubscription(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/subscription/start', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    cancelSubscription() {
      return new Promise((resolve, reject) => {
        axios.post('/billing/subscription/cancel').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    switchSubscription(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/billing/subscription/switch', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePaymentMethod(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/billing/payment', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getStatement(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/statement', { params: data }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
