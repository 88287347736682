import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    login(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/login', data).then(response => {
          // store.commit('SET_LOGIN_TIME', new Date())
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    register(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/register', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createPaymentMethod() {
      return new Promise((resolve, reject) => {
        axios.get('/subscription/createPaymentMethod').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setStripe(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/subscription/createPaymentMethod', {
          payment_method: data,
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    switchLogout(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/switchLogout', {
          unique_id: data,
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    switchLogin(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/switchLogin', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    forgotPassword(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/forgotPassword', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    logout() {
      return new Promise((resolve, reject) => {
        axios.post('/logout').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // proflie
    getProfile() {
      return new Promise((resolve, reject) => {
        axios.get('/profile').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateUser(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/profile/user', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // account
    updateAccount(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/profile/account', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addAccount(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/account/create', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // password
    resetPassword(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/profile/resetPassword', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    reqUserIdUpdate(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/profile/user/changeRequest', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // api
    refreshAPI(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/profile/refreshApiKey', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getGeneralStats(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/account/getGeneralStats', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
