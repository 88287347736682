import axios from '@/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSocialMediaList(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/' + data.account_id + '/socialMedia').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSocialMediaByType(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/' + data.account_id + '/socialMedia/' + data.type).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addSocialMedia(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/' + data.account_id + '/socialMedia', data.options).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteSocialMedia(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/superuser/' + data.account_id + '/socialMedia', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getInstaPages(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('https://graph.facebook.com/v11.0/me/accounts?access_token=' + data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getInstaAccount(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('https://graph.facebook.com/v11.0/' + data.page_id + '?fields=instagram_business_account&access_token=' + data.access_token).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
