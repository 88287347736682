import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getAccounts() {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/accounts').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getAccount(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/accounts/' + data.id).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateUser(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/superuser/accounts/users/' + data.id, data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateAccount(store, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/superuser/accounts/update/${data.get('id')}`, data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDashBoard() {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/dashboard').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getClients(store, data) {
      console.log('getClients', data)
      return new Promise((resolve, reject) => {
        axios.get('/superuser/dashboard/clients', { params: data }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSales(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/dashboard/sales', { params: data }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getAllTickets() {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/tickets').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getTicket(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/tickets/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addComment(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/tickets/' + data.id + '/comment', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    closeTicket(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/superuser/tickets/' + data + '/close', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getOffices() {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/offices').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createOffice(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/offices/create', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    startSubscription(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/accounts/' + data.id + '/subscription/start', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    switchSubscription(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/accounts/' + data.id + '/subscription/switch', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    cancelSubscription(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/accounts/' + data.id + '/subscription/cancel', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    resumeSubscription(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/accounts/' + data.id + '/subscription/resume').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getRecords(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/logs', {
          params: {
            page: data.page,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSubRecords(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/logs/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/' + data.id + '/listings', { params: data.options }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSingleListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/' + data.account_id + '/listings/' + data.system_id).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateSingleListing(store, data) {
      console.log('send req')
      return new Promise((resolve, reject) => {
        axios.post('/superuser/' + data.account_id + '/listings/' + data.system_id + '/update', data.options).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addSingleListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/' + data.account_id + '/listings/create', data.options).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteListings(store, data) {
      console.log(data)
      // {
      //   params: {
      //     listings: data.options,
      //   },
      // }
      return new Promise((resolve, reject) => {
        axios.post('/superuser/' + data.account_id + '/listings/delete', {
          listings: data.options,
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    importCSVListings(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/' + data.account_id + '/listings/import', data.options).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getPredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/' + data.id + '/predefines').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSinglePredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/superuser/' + data.account_id + '/predefines/' + data.predefine_id).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createPredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/superuser/' + data.id + '/predefines', data.options).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/superuser/' + data.account_id + '/predefines/' + data.predefine_id, data.options).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deletePredefine(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/superuser/${data.account_id}/predefines/${data.predefine_id}`, { params: { password: data.password } }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
