import axios from '@/axios'
import wpAxios from '@/wpAxios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getStyles() {
      return new Promise((resolve, reject) => {
        axios.get('/styles').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getNumberOfStlye() {
      return new Promise((resolve, reject) => {
        axios.get('/styles/files').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateTemplates(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/styles/update', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        axios.get('/website/settings').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateSettings(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/website/settings/update', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getLeads(store, data) {
      return new Promise((resolve, reject) => {
        wpAxios.post(data.website + '/wp-json/api/getLeadHistory', data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
