export default {
  clientInfo: {},
  loginTime: '',
  EXPIRATION_TIME: 2,
  isAddAccountModalShow: false,
  isAddCardModalShow: false,
  isEditCardModalShow: false,
  isDeleteCardModalShow: false,
  fbUserAccessToken: '',
  fbPageAccessToken: '',
  fbPageID: '',
  // STRIPE_PUBLIC_KEY: 'pk_test_FqdoyPkos3G07s3QYQFTgUED005k7HWpgD',
  STRIPE_PUBLIC_KEY: 'pk_live_VA1tHtbCT4zctB9OYsgvOFiS',
  MAX_FILE_UPLOAD_SIZE: 1000,
}
