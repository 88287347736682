import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getTeamMembers() {
      return new Promise((resolve, reject) => {
        axios.get('/account/members').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addTeamMember(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/account/members', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getMemberDetail(store, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/account/members/${data.unique_id}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateMember(store, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/account/members/${data.unique_id}`, data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteUser(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/account/members/${data.unique_id}`, { params: { password: data.password } }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    activateMember(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/account/members/activation', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    activateMemberViaMail(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/account/activation/', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },

  },
}
