// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from './store/index'
// import store from '@/store'

// const domain = 'http://new.viii.test/api'
let domain = 'https://final.viii.ca/api'
if (process.env.NODE_ENV === 'development') {
  domain = 'http://api.viii.onikon.local/api'
  // domain = 'https://final.viii.ca/api'
}
// console.log(process.env.API_PATH)
// console.log('------------')
// if (process.env.API_PATH) {
//   domain = process.env.API_PATH
// }

console.log(localStorage.getItem('token'))

const axiosInstance = axios.create({
  baseURL: domain,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
  // You can add your headers here
})
axiosInstance.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.accesslocation = 'dashboard'

  return config
})

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  // console.error(error)
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    store.commit('SET_LOGIN_TIME', '')
    store.commit('setClientInfo', {})
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

export default axiosInstance
