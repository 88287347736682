import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getOptionsList(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/options', {
          params: {
            formatName: data.options,
            class: data.class,
            columnName: null,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getAllListings(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/all', {
          params: {
            status: data.status,
            orderBy: data.orderBy,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //  residential
    getResidentiaListings(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/residential/all', {
          params: {
            status: data.status,
            orderBy: data.orderBy,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSingleListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/residential/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateExListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/residential/update', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/residential/add', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    importResidentialCsv(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/residential/import', data).then(response => {
          console.log(response)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    importResidentialMls(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/residential/mlsId/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteResidential(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/listings/residential', { data }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addResidentialMedia(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('listings/residential/update/embed', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // commercial
    getCommercialListings(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/commercial/all', {
          params: {
            status: data.status,
            orderBy: data.orderBy,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSingleCommercialListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/commercial/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateCommercialListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/commercial/update', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addCommercialListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/commercial/add', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    importCommercialMls(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/commercial/mlsId/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // TODO 테스트해야됨
    importCommercialCsv(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/commercial/import', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteCommercial(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/listings/commercial', { data }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addCommercialMedia(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('listings/commercial/update/embed', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // rental
    getRentalListings(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/rental/all', {
          params: {
            status: data.status,
            orderBy: data.orderBy,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSingleRentalListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/rental/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addRentalListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/rental/add', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateRentalListing(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/rental/update', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // TODD: 테스트해야됨
    importRentalMls(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/listings/rental/mlsId/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // TODO: 테스트해야됨
    importRentalCsv(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/listings/rental/import', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteRental(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/listings/rental', { data }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addRentalMedia(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('listings/rental/update/embed', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
