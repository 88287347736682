import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/medias',
      name: 'Medias',
      component: () => import('@/views/Medias/Medias.vue'),
      meta: {
        pageTitle: 'Medias',
        breadcrumb: [
          {
            text: 'Medias',
            active: true,
          },
        ],
      },
    },
    {
      path: '/residential',
      name: 'residential',
      component: () => import('@/views/Listings/Residentials/List.vue'),
      meta: {
        pageTitle: 'Residential Listing',
        breadcrumb: [
          {
            text: 'Residential Listing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/residential/create/:account?',
      name: 'create-residential',
      component: () => import('@/views/Listings/Residentials/Create.vue'),
      meta: {
        pageTitle: 'Create Residential Listing',
        link: 'residential',
        breadcrumb: [
          {
            text: 'Residential Listing',
            active: false,
            path: '/residential',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/residential/:id/:account?',
      name: 'edit-residential',
      component: () => import('@/views/Listings/Residentials/Edit.vue'),
      meta: {
        pageTitle: 'Edit Residential Listing',
        link: 'residential',
        breadcrumb: [
          {
            text: 'Residential Listing',
            active: false,
            path: '/residential',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commercial',
      name: 'commercial',
      component: () => import('@/views/Listings/Commercials/List.vue'),
      meta: {
        pageTitle: 'Commercial Listing',
        breadcrumb: [
          {
            text: 'Commercial Listing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commercial/create/:account?',
      name: 'create-commercial',
      component: () => import('@/views/Listings/Commercials/Create.vue'),
      meta: {
        pageTitle: 'Create Commercial Listing',
        link: 'commercial',
        breadcrumb: [
          {
            text: 'Commercial Listing',
            active: false,
            path: '/commercial',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commercial/:id',
      name: 'edit-commercial',
      component: () => import('@/views/Listings/Commercials/Edit.vue'),
      meta: {
        pageTitle: 'Edit Commercial Listing',
        link: 'commercial',
        breadcrumb: [
          {
            text: 'Commercial Listing',
            active: false,
            path: '/commercial',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rental',
      name: 'rental',
      component: () => import('@/views/Listings/Rentals/List.vue'),
      meta: {
        pageTitle: 'Rental Listing',
        breadcrumb: [
          {
            text: 'Rental Listing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rental/create/:account?',
      name: 'create-rental',
      component: () => import('@/views/Listings/Rentals/Create.vue'),
      meta: {
        pageTitle: 'Create Rental Listing',
        link: 'rental',
        breadcrumb: [
          {
            text: 'Rental Listing',
            active: false,
            path: '/rental',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rental/:id',
      name: 'edit-rental',
      component: () => import('@/views/Listings/Rentals/Edit.vue'),
      meta: {
        pageTitle: 'Edit Rental Listing',
        link: 'rental',
        breadcrumb: [
          {
            text: 'Rental Listing',
            active: false,
            path: '/rental',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leads',
      name: 'leads',
      component: () => import('@/views/Leads/List.vue'),
      meta: {
        pageTitle: 'Leads',
        breadcrumb: [
          {
            text: 'Leads',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leads/create',
      name: 'create-lead',
      component: () => import('@/views/Leads/Create.vue'),
      meta: {
        pageTitle: 'Leads',
        breadcrumb: [
          {
            text: 'Leads',
            active: false,
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leads/:id',
      name: 'lead-detail',
      component: () => import('@/views/Leads/Detail.vue'),
      meta: {
        pageTitle: 'Leads',
        link: 'leads',
        breadcrumb: [
          {
            text: 'Leads',
            active: false,
            path: '/leads',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leads/:id/edit',
      name: 'lead-edit',
      component: () => import('@/views/Leads/Edit.vue'),
      meta: {
        pageTitle: 'Leads',
        link: 'leads',
        breadcrumb: [
          {
            text: 'Leads',
            active: false,
            path: '/leads',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/predefines',
      name: 'predefines',
      component: () => import('@/views/Predefines/List.vue'),
      meta: {
        pageTitle: 'Predefines',
        breadcrumb: [
          {
            text: 'Predefines',
            active: true,
          },
        ],
      },
    },
    {
      path: '/predefines/create',
      name: 'create-predefine',
      component: () => import('@/views/Predefines/Create.vue'),
      meta: {
        pageTitle: 'Create Predefine',
        link: 'predefines',
        breadcrumb: [
          {
            text: 'Predefines',
            active: false,
            path: '/predefines',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/predefines/:id',
      name: 'edit-predefine',
      component: () => import('@/views/Predefines/Edit.vue'),
      meta: {
        pageTitle: 'Edit Predefine',
        link: 'predefines',
        breadcrumb: [
          {
            text: 'Predefines',
            active: false,
            path: '/predefines',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('@/views/Profile/Detail.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account/create',
      name: 'Create Account',
      component: () => import('@/views/Account/Create.vue'),
      // meta: {
      //   layout: 'full',
      // },
    },
    // {
    //   path: '/profile/edit',
    //   name: 'Edit Profile',
    //   component: () => import('@/views/Profile/Edit.vue'),
    //   meta: {
    //     pageTitle: 'Profile',
    //     breadcrumb: [
    //       {
    //         text: 'Profile',
    //         active: false,
    //         path: '/profile',
    //       },
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users/List.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/create',
      name: 'Create User',
      component: () => import('@/views/Users/Create.vue'),
      meta: {
        pageTitle: 'Create User',
        link: 'users',
        breadcrumb: [
          {
            text: 'Users',
            active: false,
            path: '/users',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:id/:isAdmin',
      name: 'Edit Users',
      component: () => import('@/views/Users/Edit.vue'),
      meta: {
        pageTitle: 'Edit User',
        link: 'users',
        breadcrumb: [
          {
            text: 'Users',
            active: false,
            path: '/users',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams',
      name: 'teams',
      component: () => import('@/views/Teams/List.vue'),
      meta: {
        pageTitle: 'Teams',
        breadcrumb: [
          {
            text: 'Teams',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams/create',
      name: 'Create Team',
      component: () => import('@/views/Teams/Create.vue'),
      meta: {
        pageTitle: 'Create Team',
        link: 'teams',
        breadcrumb: [
          {
            text: 'Teams',
            active: false,
            path: '/teams',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams/:id/:isAdmin',
      name: 'Edit Teams',
      component: () => import('@/views/Teams/Edit.vue'),
      meta: {
        pageTitle: 'Edit Team',
        link: 'teams',
        breadcrumb: [
          {
            text: 'Teams',
            active: false,
            path: '/teams',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brokerage',
      name: 'brokerage',
      component: () => import('@/views/Brokerages/List.vue'),
      meta: {
        pageTitle: 'Brokerage',
        breadcrumb: [
          {
            text: 'Brokerage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brokerage/create',
      name: 'Create Brokerage',
      component: () => import('@/views/Brokerages/Create.vue'),
      meta: {
        pageTitle: 'Create Brokerage',
        link: 'brokerage',
        breadcrumb: [
          {
            text: 'Brokerage',
            active: false,
            path: '/brokerage',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brokerage/:id',
      name: 'Edit Brokerage',
      component: () => import('@/views/Brokerages/Edit.vue'),
      meta: {
        pageTitle: 'Edit Agent',
        link: 'brokerage',
        breadcrumb: [
          {
            text: 'Brokerage',
            active: false,
            path: '/brokerage',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts',
      name: 'Accounts',
      component: () => import('@/views/SuperAdmin/Accounts/List.vue'),
      meta: {
        pageTitle: 'Accounts',
        breadcrumb: [
          {
            text: 'Accounts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/create',
      name: 'Create Client',
      component: () => import('@/views/SuperAdmin/Accounts/Create.vue'),
      meta: {
        pageTitle: 'Create Account',
        link: 'accounts',
        breadcrumb: [
          {
            text: 'Accounts',
            active: false,
            path: 'accounts',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/:id',
      name: 'Account Detail',
      component: () => import('@/views/SuperAdmin/Accounts/Detail.vue'),
      meta: {
        pageTitle: 'Client Detail',
        link: 'accounts',
        breadcrumb: [
          {
            text: 'Accounts',
            active: false,
            path: 'accounts',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support',
      name: 'Support',
      component: () => import('@/views/Support/List.vue'),
      meta: {
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/create',
      name: 'Create Ticket',
      component: () => import('@/views/Support/Create.vue'),
      meta: {
        pageTitle: 'Create Ticket',
        breadcrumb: [
          {
            text: 'Support',
            active: false,
            path: '/support',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/support/:id',
      name: 'Support Ticket',
      component: () => import('@/views/Support/Detail.vue'),
      meta: {
        pageTitle: 'Support Ticket',
        breadcrumb: [
          {
            text: 'Support',
            active: false,
            path: '/support',
          },
          {
            text: 'Ticket',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import('@/views/SuperAdmin/Tickets/List.vue'),
      meta: {
        pageTitle: 'Tickets',
        breadcrumb: [
          {
            text: 'Tickets',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/:id',
      name: 'Ticket Detail',
      component: () => import('@/views/SuperAdmin/Tickets/Detail.vue'),
      meta: {
        pageTitle: 'Ticket Detail',
        link: 'tickets',
        breadcrumb: [
          {
            text: 'Tickets',
            active: false,
            path: '/tickets',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offices',
      name: 'offices',
      component: () => import('@/views/SuperAdmin/Offices/List.vue'),
      meta: {
        pageTitle: 'Offices',
        breadcrumb: [
          {
            text: 'Offices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offices/create',
      name: 'Create Office',
      component: () => import('@/views/SuperAdmin/Offices/Create.vue'),
      meta: {
        pageTitle: 'Create Office',
        breadcrumb: [
          {
            text: 'Offices',
            active: false,
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/records',
      name: 'records',
      component: () => import('@/views/SuperAdmin/Records/List.vue'),
      meta: {
        pageTitle: 'Records',
        breadcrumb: [
          {
            text: 'Records',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/billings',
    //   name: 'Billings',
    //   component: () => import('@/views/Billings/List.vue'),
    //   meta: {
    //     pageTitle: 'Billings',
    //     breadcrumb: [
    //       {
    //         text: 'Billings',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/billings',
      name: 'Billings',
      component: () => import('@/views/Billings/List.vue'),
      meta: {
        pageTitle: 'Billings',
        breadcrumb: [
          {
            text: 'Billings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billings/statement',
      name: 'Statement Detail',
      component: () => import('@/views/Billings/Statement.vue'),
      meta: {
        pageTitle: 'Statement',
        link: 'Billings',
        breadcrumb: [
          {
            text: 'Billings',
            active: false,
            path: '/billings',
          },
          {
            text: 'Statement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billings/:id',
      name: 'Billings Detail',
      component: () => import('@/views/Billings/Detail.vue'),
      meta: {
        pageTitle: 'Invoice Detail',
        link: 'Billings',
        breadcrumb: [
          {
            text: 'Billings',
            active: false,
            path: '/billings',
          },
          {
            text: 'Invoice',
            active: true,
          },
        ],
      },
    },

    {
      path: '/superadmin/billings',
      name: 'Superadmin Billings',
      component: () => import('@/views/SuperAdmin/Billings/List.vue'),
      meta: {
        pageTitle: 'Billings',
        breadcrumb: [
          {
            text: 'Billings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/superadmin/billings/:clientId',
      name: 'Superadmin Invoices',
      component: () => import('@/views/SuperAdmin/Billings/Invoices.vue'),
      meta: {
        pageTitle: 'Client Invoices',
        link: 'Superadmin Billings',
        breadcrumb: [
          {
            text: 'Billings',
            active: false,
            path: '/billings',
          },
          {
            text: 'Invoices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/superadmin/billings/:clientId/detail/:id',
      name: 'Superadmin Invoices Detail',
      component: () => import('@/views/SuperAdmin/Billings/Detail.vue'),
      meta: {
        pageTitle: 'Client Invoice Detail',
        link: 'Superadmin Billings',
        breadcrumb: [
          {
            text: 'Billings',
            active: false,
            path: '/billings',
          },
          {
            text: 'Invoices',
            active: false,
            path: '/superadmin/billings/:clientId',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/init',
      name: 'init',
      component: () => import('@/views/Authentication/InitAccount.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/Authentication/forgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Authentication/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/form',
      name: 'form1',
      component: () => import('@/views/Authentication/ResetForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/form_2',
      name: 'form2',
      component: () => import('@/views/Authentication/form_2.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/setting',
      name: 'Setting',
      component: () => import('@/views/Website/Setting/Edit.vue'),
      meta: {
        pageTitle: 'Setting',
        breadcrumb: [
          {
            text: 'Setting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/style',
      name: 'Style',
      component: () => import('@/views/Website/Style/Edit.vue'),
      meta: {
        pageTitle: 'Style',
        breadcrumb: [
          {
            text: 'Style',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (store.state.loginTime) {
    const last = store.state.loginTime
    const current = new Date().getTime()
    let dateDiff = current - last
    dateDiff = Math.abs(dateDiff)
    const hourDiff = dateDiff / 1000 / 3600

    if (hourDiff > store.state.EXPIRATION_TIME) {
      store.dispatch('users/logout').then(() => {
        localStorage.removeItem('token')
        store.commit('SET_LOGIN_TIME', '')
        store.commit('setClientInfo', {})
        // eslint-disable-next-line no-restricted-globals
        next('/login')
      }).catch(err => {
        console.error(err)
      })
    } else if (to.name !== 'login' || to.name === 'register') {
      store.commit('SET_LOGIN_TIME', current)
    }
  }

  if (!Object.keys(store.state.clientInfo).length) {
    localStorage.removeItem('token')
    store.commit('SET_LOGIN_TIME', '')
    store.commit('setClientInfo', {})
  }

  if (store.state.isDeleteCardModalShow) {
    store.commit('SET_IS_DELETE_CARD', false)
  }

  if (store.state.isEditCardModalShow) {
    store.commit('SET_IS_EDIT_CARD', false)
  }

  if (store.state.isAddCardModalShow) {
    store.commit('SET_IS_ADD_CARD', false)
  }

  // if (store.state.clientInfo.detail.is_init === '0' && to.name !== 'init' && store.state.clientInfo.memberType !== 'superuser') {
  //   next('/init')
  // }

  if (to.name === 'login' || to.name === 'register' || to.name === 'forgot-password') {
    if (localStorage.getItem('token')) {
      next('/')
    } else {
      next()
    }
  } else if (localStorage.getItem('token')) {
    const current = new Date().getTime()
    store.commit('SET_LOGIN_TIME', current)

    next()
  } else {
    next('/login')
  }
})

export default router
