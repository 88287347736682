<template>
  <validation-observer
    ref="addAccountForm"
    #default="{invalid}"
  >
    <b-modal
      v-model="getAddModalShow"
      ok-title="Save"
      ok-variant="outline-primary"
      :ok-disabled="invalid"
      cancel-variant="outline-danger"
      cancel-title="Close"
      @ok="handleOk"
    >
      <form
        ref="addAccountform"
        @submit.stop.prevent="handleOk"
      >
        <div class="page">
          <h4>Add Account</h4>
          <div class="auth-inner py-2">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="name"
                    v-model="data.name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                  />
                  <!-- @input="formatPhone(user.phone)" -->
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Member Type"
              label-for="member-type"
            >
              <v-select
                id="member-type"
                v-model="data.memberType"
                :options="memberTypeOptions"
                name="member-type"
                :clearable="false"
                :searchable="false"
                label="text"
                :reduce="memberType => memberType.value"
              />
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <v-select
                id="role"
                v-model="data.role"
                :options="roleOptions"
                name="role"
                :clearable="false"
                :searchable="false"
                label="text"
                :reduce="role => role.value"
              />
            </b-form-group>

            <b-form-group
              label="Website"
              label-for="website"
            >
              <validation-provider
                #default="{ errors }"
                name="Website"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="website"
                    v-model="data.website"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                  />
                  <!-- @input="formatPhone(user.phone)" -->
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-alert
              v-if="errorMsg"
              variant="danger"
              show
            >
              <div class="alert-body">
                {{ errorMsg }}
              </div>
            </b-alert>
          </div>
        </div>
      </form>
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormGroup, BAlert, BModal, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BFormGroup,
    vSelect,
    BAlert,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
  },
  data() {
    return {
      memberTypeOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'team', text: 'Team' },
        { value: 'brokerage', text: 'Brokerage' },
      ],
      roleOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'manager', text: 'Mananger' },
        { value: 'assistant', text: 'Assistant' },
      ],
      data: {
        role: 'agent',
        memberType: 'agent',
        website: '',
        name: '',
      },
      required,
      errorMsg: '',
    }
  },
  computed: {
    getAddModalShow: {
      get() {
        return this.$store.state.isAddAccountModalShow
      },
      set(newVal) {
        this.$store.commit('SET_IS_ADD_MODAL', newVal)
      },
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (!this.$refs.addAccountform.checkValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }

      this.$store.dispatch('users/addAccount', this.data)
        .then(response => {
          if (response.code === 200) {
            this.showToast('Success', 'CheckCircleIcon', 'Agent has been added successfully', 'success', 'bottom-right')
            this.$store.commit('SET_IS_ADD_MODAL', false)
          }
        })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vs__open-indicator{
  display:none;
}
</style>
