/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from './users'
import supports from './supports'
import listings from './listings'
import offices from './offices'
import predefines from './predefines'
import billings from './billings'
import leads from './leads'
import teams from './teams'
import superadmin from './superadmin'
import website from './website'
import social from './social'
import mutations from './mutations'
import state from './state'
import media from './media'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    listings,
    offices,
    supports,
    teams,
    predefines,
    billings,
    website,
    leads,
    media,
    superadmin,
    social,
  },
  state,
  mutations,
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})
