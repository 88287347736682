// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
// import store from './store/index'
// import store from '@/store'

const domain = 'https://photos.idx.hybridworks.ca/api'
const axiosInstance = axios.create({
  baseURL: domain,
  headers: {
    Authorization: 'Bearer Z2R2S2VwNHpSdzVaanZVQkdNZmtMdTRjZjJoR0c0MFpWcldkOFhUQg==',
  },
  // You can add your headers here
})

export default axiosInstance
