// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
// import store from './store/index'
// import store from '@/store'
// const domain = 'http://new.viii.test/api'
const axiosInstance = axios.create({
  // baseURL: domain,
  // headers: {
  //   Authorization: 'Bearer ' + localStorage.getItem('token'),
  // },
  // You can add your headers here
})

// axiosInstance.interceptors.response.use(response => {
//   return response
// }, error => {
//   console.error(error)
//   if (error.response.status === 401) {
//     localStorage.removeItem('token')
//     store.commit('SET_LOGIN_TIME', '')
//     store.commit('setClientInfo', {})
//     window.location.href = '/login'
//   }
//   return Promise.reject(error)
// })

export default axiosInstance
