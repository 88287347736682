import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getTicketCategories() {
      return new Promise((resolve, reject) => {
        axios.get('/support/ticket/category').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createTicket(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/support/ticket/open', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getTickets() {
      return new Promise((resolve, reject) => {
        axios.get('/support/ticket/all').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getTicket(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/support/ticket/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addComment(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/support/ticket/' + data.id + '/comment', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    closeTicket(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/support/ticket/' + data + '/close', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
