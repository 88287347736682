import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getOfficeNames(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/register/officeAutocomplete', { q: data.key }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getMembers() {
      return new Promise((resolve, reject) => {
        axios.get('/account/brokerage/members').then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    syncMembers(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/account/brokerage/sync', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getAgent(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/account/brokerage/members/' + data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateAgent(store, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/account/brokerage/members/update/${data.get('id')}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addAgent(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/account/brokerage/members', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    removeAgent(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/account/brokerage/members/' + data.id, {
          params: {
            password: data.password,
            id: data.id,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
