import axios from '@/axios_hybrid'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getPhotos(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/photo', {
          params: {
            page: data.page ? data.page : 1,
            account_id: data.account_id,
            keyword: data.keyword,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    uploadPhoto(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/photo', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updatePhoto(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/photo', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deletePhoto(store, data) {
      return new Promise((resolve, reject) => {
        axios.delete('/photo', {
          params: {
            photo_id: data.photo_id,
            account_id: data.account_id,
          },
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
