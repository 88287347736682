import axios from '@/axios'
import wpAxios from '@/wpAxios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getLeads(store, data) {
      return new Promise((resolve, reject) => {
        wpAxios.post(data.website + '/wp-json/api/getLeadHistory', data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getLeadUsers() {
      return new Promise((resolve, reject) => {
        axios.get('/leads').then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getLeadUser(store, data) {
      return new Promise((resolve, reject) => {
        axios.get('/leads/' + data.id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createLead(store, data) {
      return new Promise((resolve, reject) => {
        axios.post('/leads', data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateLead(store, data) {
      return new Promise((resolve, reject) => {
        axios.put('/leads/' + data.id, data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
